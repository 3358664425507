import React from "react";
// import App from "./App";
import { Link } from "react-router-dom";

function List() {
  return (
    <div>
      {/* <Link to="/DC014" /> */}
      <Link to="/uc008" />
      {/* <Link to="/DC010" /> */}
      {/* <Link to="/DC006" /> */}
      {/* <App {...props} /> */}
    </div>
  );
}

export default List;
