import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import BandcampLogo from "./img/bandcamp.png";
import SpotifyLogo from "./img/spotify.png";
import JunoLogo from "./img/juno.png";
import BeatportLogo from "./img/beatport.png";
import ITunesLogo from "./img/itunes.png";
import AppleLogo from "./img/apple-music.png";
import SoundCloudLogo from "./img/soundcloud.png";
import YouTubeLogo from "./img/youtube.png";
import TraxSourceLogo from "./img/traxsource.png";
import "./App.css";

function App() {
  let { releaseId = "" } = useParams();
  const [release, setRelease] = useState({});
  useEffect(() => {
    const readFile = async () => {
      const markdownFilePath = `./releases/${releaseId.toUpperCase()}.json`;
      try {
        const markdownFile = await require(`${markdownFilePath}`);
        setRelease(markdownFile);
      } catch (error) {
        window.location.href = "https://www.dreamchimney.com";
      }
    };
    if (releaseId) {
      readFile();
    }
  }, [releaseId]);

  console.log("🚀 ~ file: App.js:33 ~ App ~ release:", release);
  if (!release) return <div>Loading...</div>;
  return (
    <div className="application">
      <Helmet>
        <title>{`Add to Cart: ${release.title} by ${release.artist}`}</title>
        <meta property="og:description" content={`Buy the release ${release.title} by ${release.artist} on ${release.label}`} />
        <link rel="canonical" href={`https://addtocart.dreamchimney.com/${releaseId}/`} />
        <meta property="fb:app_id" content="619124338182144" />
        <meta property="og:title" content={`${release.title} by ${release.artist}`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://addtocart.dreamchimney.com/${releaseId}/`} />
        <meta property="og:image" content={release.coverImageShare} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@dreamchimney" />
        <meta name="twitter:creator" content="@dreamchimney" />
        <meta property="twitter:image" content={release.coverImageShare} />
        <meta property="twitter:title" content={`${release.title} by ${release.artist}`} />
        <meta property="twitter:image:alt" content={`${release.title} by ${release.artist}`} />
        <meta name="description" content={`Buy the release ${release.title} by ${release.artist} on ${release.label}`} />
      </Helmet>
      <div className="App link-view music-view">
        <div className="row center middle link-wrap-bg-layer">
          <div className="link-wrap-bg" style={{ backgroundImage: `url(${release.coverImage})` }}>
            <div className="link-wrap-bg-top"></div>
          </div>
        </div>
        <div className="row center middle link-wrap">
          <div className="col col-sm-12 link-main">
            <div className="row center top">
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="row middle link-cover-wrap">
                  <div className="col col-lg-12 col-xs-12 link-cover">
                    <img src={release.coverImage} alt={release.title} />
                  </div>
                  {release.soundCloudStreamId && (
                    <div className="col col-lg-12 col-xs-12 embed-cover">
                      <iframe
                        width="100%"
                        height="20"
                        scrolling="no"
                        frameborder="no"
                        allow="autoplay"
                        src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${release.soundCloudStreamId}&color=%23ff5500&inverse=false&auto_play=false&show_user=true`}
                      ></iframe>
                    </div>
                  )}
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="link-container-wrap">
                  <div className="link-container">
                    <div className="link-container-body">
                      <div>
                        <div className="row middle link-header link-header-music">
                          <div className="col col-12 link-header-title">
                            <h1>{release.artist}</h1>
                            <h2>{release.title}</h2>
                            <p>Label: {release.label}</p>
                            <p>Release date: {release.date}</p>
                            {release.subText && <p>{release.subText}</p>}
                            {release.extra && (
                              <div className="extra" style={{ padding: "10px 0" }} dangerouslySetInnerHTML={{ __html: release.extra }} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row middle link-options">
                        <span></span>
                        {release.bandcamp && (
                          <a className="col col-12  link-option-row" href={release.bandcamp.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={BandcampLogo} alt="Bandcamp" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.bandcamp.action}</div>
                            </div>
                          </a>
                        )}

                        {release.spotify && (
                          <a className="col col-12 link-option-row" href={release.spotify.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={SpotifyLogo} alt="spotify" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.spotify.action}</div>
                            </div>
                          </a>
                        )}

                        {release.apple && (
                          <a className="col col-12 link-option-row" href={release.apple.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={AppleLogo} alt="apple" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.apple.action}</div>
                            </div>
                          </a>
                        )}

                        {release.itunes && (
                          <a className="col col-12 link-option-row" href={release.itunes.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={ITunesLogo} alt="itunes" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.itunes.action}</div>
                            </div>
                          </a>
                        )}
                        {release.juno && (
                          <a className="col col-12 link-option-row" href={release.juno.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={JunoLogo} alt="Juno" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.juno.action}</div>
                            </div>
                          </a>
                        )}
                        {release.beatport && (
                          <a className="col col-12 link-option-row" href={release.beatport.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={BeatportLogo} alt="Beatport" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.beatport.action}</div>
                            </div>
                          </a>
                        )}
                        {release.traxsource && (
                          <a className="col col-12 link-option-row" href={release.traxsource.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={TraxSourceLogo} alt="TraxSource" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.traxsource.action}</div>
                            </div>
                          </a>
                        )}
                        {release.soundcloud && (
                          <a className="col col-12 link-option-row" href={release.soundcloud.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={SoundCloudLogo} alt="soundcloud" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.soundcloud.action}</div>
                            </div>
                          </a>
                        )}

                        {release.youtube && (
                          <a className="col col-12 link-option-row" href={release.youtube.url}>
                            <div className="row middle">
                              <div className="col col-lg-7 col-sm-7 col-med-7 link-option-row-title">
                                <span>
                                  <img className="link-option-row-img" src={YouTubeLogo} alt="youtube" />
                                </span>
                              </div>
                              <div className="col col-lg-5 col-sm-5 col-med-5 link-option-row-action">{release.youtube.action}</div>
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
