import React from "react";
// import ReactDOM from "react-dom/client";
import { hydrate, render } from "react-dom";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import "./index.css";
import List from "./List";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");

console.log("🚀 ~ file: index.js:11 ~ process.env.BUILD_ENV:", process.env);
if (true) {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <List />,
    },
    {
      path: "/:releaseId",
      element: <App />,
    },
  ]);
  if (rootElement.hasChildNodes()) {
    hydrate(
      <React.StrictMode>
        <React.Fragment>
          <RouterProvider router={router} />
        </React.Fragment>
      </React.StrictMode>,
      rootElement
    );
  } else {
    render(
      <React.StrictMode>
        <React.Fragment>
          <RouterProvider router={router} />
        </React.Fragment>
      </React.StrictMode>,
      rootElement
    );
  }
} else {
  const router = createBrowserRouter([
    {
      path: "/:releaseId",
      element: <App />,
    },
  ]);
  render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
